import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const EngMetaverseGamingSpacePage = loadable(() => import("../components/MetaverseGamingSpacePage/english.js"));
const ArabicMetaverseGamingSpacePage = loadable(() => import("../components/MetaverseGamingSpacePage/arabic.js"));
const ChineseMetaverseGamingSpacePage = loadable(() => import("../components/MetaverseGamingSpacePage/chinese.js"));


export class MetaverseGamingSpacePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: "English",
        }
    };

    async componentDidMount() {
        let region = localStorage.getItem('region');

        if (region == 'ae') this.setState({ lang: "Arabic" });
        // else if (region == 'sa') this.setState({ lang: "Arabic" });
        else if (region == 'cn') this.setState({ lang: "China" });
        else this.setState({ lang: "English" });
    };

    render() {
        let { lang } = this.state;
        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        path="/metaverse-gaming-space-development-services/"
                        title="Leading Metaverse Gaming Space Development Firm | Softtik"
                        thumbnail="https://softtik.com/images/nft-collection/about-token.webp"
                        description="Hop onto the rising technology bandwagon with our top-notch Metaverse gaming space development services to boost users' gaming experience."
                    />
                    <main>
                        <div id="wrapper">
                            <div className={`collection-page metaverse-token-page ${lang == "Arabic" && 'rtl'}`}>
                                {lang == "Arabic"
                                    ? <ArabicMetaverseGamingSpacePage />
                                    : lang == "China"
                                        ? <ChineseMetaverseGamingSpacePage />
                                        : <EngMetaverseGamingSpacePage />
                                }
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default MetaverseGamingSpacePage;